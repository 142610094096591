import axiosIns from '@/libs/axios';
const state = {
  bildirimler: [],
};
const getters = {
  getBildirimler: (state) => state.bildirimler,
};
const mutations = {
  SET_BILDIRIM(state, payload) {
    state.bildirimler = payload;
  },
  SET_BILDIRIM_PUSH(state, payload) {
    state.bildirimler.push(payload);
  },
  SET_BILDIRIM_UPDATE(state, payload) {
    let index = state.bildirimler.findIndex((x) => x._id == payload._id);
    state.bildirimler[index] = payload;
  },
  SET_BILDIRIM_DELETE(state, k_no) {
    let index = state.bildirimler.findIndex((x) => x.k_no == k_no);
    state.bildirimler.splice(index, 1);
  },
};
const actions = {
  async bildirimListele({ commit }, data) {
    return await axiosIns.post('/ayarlar/bildirim-listele', data).then((res) => {
      commit('SET_BILDIRIM', res.data.data);
      return res;
    });
  },
  async bildirimEkle({ commit }, data) {
    return await axiosIns.post('/ayarlar/bildirim-ekle', data).then((res) => {
      commit('SET_BILDIRIM_PUSH', res.data.data);
      return res;
    });
  },
  async bildirimGuncelle({ commit }, data) {
    return await axiosIns.post('/ayarlar/bildirim-guncelle', data).then((res) => {
      commit('SET_BILDIRIM_UPDATE', res.data.data);
      return res;
    });
  },
  async bildirimSil({ commit }, k_no) {
    return await axiosIns.post('/ayarlar/bildirim-sil', { k_no }).then((res) => {
      commit('SET_BILDIRIM_DELETE', k_no);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
