import Vue from 'vue';

// axios
import axios from 'axios';
import router from '@/router';
import store from '@/store';

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.NODE_ENV == 'development' ? process.env.VUE_APP_DEV_BASEURL : process.env.VUE_APP_PRO_BASEURL,
  timeout: 10000,
  headers: {
    'X-Custom-Header': 'foobar',
  },
});

// axiosIns.interceptors.request.use(
//   (response) => response,
//   (error) => {
//     const { response } = error;
//     if (response && response.status === 401) {
//       console.log('test');
//     }
//   }
// );

axiosIns.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;
    if (response && response.status === 401) {
      store.dispatch('logout');
    }
    return Promise.reject(response);
  }
);

Vue.prototype.$http = axiosIns;

export default axiosIns;
