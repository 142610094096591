import axiosIns from '@/libs/axios';
const state = {
  seo: [],
};
const getters = {
  getSeo: (state) => state.seo,
};
const mutations = {
  SET_SEO(state, payload) {
    state.seo = payload;
  },
  SET_SEO_DELETE(state, id) {
    let index = state.seo.findIndex((x) => x._id == id);
    state.seo.splice(index, 1);
  },
};
const actions = {
  async seoGetir({ commit }, data) {
    return await axiosIns.post('/ayarlar/seo-getir', data).then((res) => {
      commit('SET_SEO', res.data.data);
      return res;
    });
  },
  async seoEkle({ commit }, data) {
    return await axiosIns.post('/ayarlar/seo-ekle', data).then((res) => {
      commit('SET_SEO', res.data.data);
      return res;
    });
  },
  async seoSil({ commit }, _id) {
    return await axiosIns.post('/ayarlar/seo-sil', { _id }).then((res) => {
      commit('SET_SMS_DELETE', _id);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
