import axiosIns from '@/libs/axios';
const state = {
  urunler: [],
};
const getters = {
  getUrunler: (state) => state.urunler,
};
const mutations = {
  SET_URUNLER(state, payload) {
    state.urunler = payload;
  },
  SET_URUNLER_PUSH(state, payload) {
    state.urunler.push(payload);
  },
  SET_URUNLER_UPDATE(state, payload) {
    let index = state.urunler.findIndex((x) => x._id == payload._id);
    state.urunler[index] = payload;
  },
  SET_URUNLER_DELETE(state, k_no) {
    let index = state.urunler.findIndex((x) => x.k_no == k_no);
    state.urunler.splice(index, 1);
  },
};
const actions = {
  async urunListele({ commit }, data) {
    return await axiosIns.post('/urun/urun-listele', data).then((res) => {
      commit('SET_URUNLER', res.data.data);
      return res;
    });
  },
  async urunEkle({ commit }, data) {
    return await axiosIns
      .post('/urun/urun-ekle', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        commit('SET_URUNLER_PUSH', res.data.data);
        return res;
      });
  },
  async urunGuncelle({ commit }, data) {
    return await axiosIns
      .post('/urun/urun-guncelle', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        commit('SET_URUNLER_UPDATE', res.data.data);
        return res;
      });
  },
  async urunBannerSil({}, data) {
    return await axiosIns.post('/urun/urun-banner-sil', data).then((res) => {
      return res;
    });
  },
  async urunResimSiraGuncelle({}, data) {
    return await axiosIns.post('/urun/urun-resim-sira-guncelle', data).then((res) => {
      return res;
    });
  },
  async urunResimKapak({ commit }, data) {
    return await axiosIns.post('/urun/urun-resim-kapak', data).then((res) => {
      console.log(res.data.data);
      commit('SET_URUNLER_UPDATE', res.data.data);
      return res;
    });
  },
  async urunResimSil({}, data) {
    return await axiosIns.post('/urun/urun-resim-sil', data).then((res) => {
      return res;
    });
  },
  async urunSil({ commit }, k_no) {
    return await axiosIns.post('/urun/urun-sil', { k_no }).then((res) => {
      commit('SET_URUNLER_DELETE', k_no);
      return res;
    });
  },

  async urunFindOne({ commit }, k_no) {
    return await axiosIns.post('/urun/urun-find-one', { k_no }).then((res) => {
      if (res.data.success === true) {
        commit('SET_URUNLER_UPDATE', res.data.data);
        return res.data.data;
      }
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
