import Vue from 'vue';
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue';
import VueCompositionAPI from '@vue/composition-api';
import VueCookies from 'vue-cookies';
import i18n from '@/libs/i18n';
// import VueSocketIO from 'vue-socket.io';
// import SocketIO from 'socket.io-client';

import BootstrapVue from 'bootstrap-vue';
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate';

import tr from 'vee-validate/dist/locale/tr.json';
import * as rules from 'vee-validate/dist/rules';

import router from './router';
import store from './store';
import App from './App.vue';

// Global Components
import './global-components';

// 3rd party plugins
import '@/libs/portal-vue';
import '@/libs/sweet-alerts';
import '@/libs/toastification';
import '@/libs/filters';
import '@/libs/vue-sticky';
import '@/libs/vue-qrcode';

// import dotenv from 'dotenv';

// dotenv.config({
//   path: './../.env',
// });
// dotenv.config();

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

localize('tr', tr);

// Install VeeValidate components globally
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

Vue.use(BootstrapVue);

// Vue cookies
Vue.use(VueCookies);
Vue.$cookies.config('7d');

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

// import core styles
require('@core/scss/core.scss');

// import assets styles
require('@/assets/scss/style.scss');

Vue.config.productionTip = false;

//socket io connection
// const socketConnection = SocketIO('http://localhost:6807');
// Vue.use(
//   new VueSocketIO({
//     debug: false,
//     connection: socketConnection,
//   })
// );

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
