import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  eposta_ayarlari: [],
};
const getters = {
  getEpostaAyarlari: (state) => state.eposta_ayarlari,
};
const mutations = {
  SET_EPOSTA(state, payload) {
    state.eposta_ayarlari = payload;
  },
  SET_EPOSTA_DELETE(state, id) {
    let index = state.eposta_ayarlari.findIndex((x) => x._id == id);
    state.eposta_ayarlari.splice(index, 1);
  },
};
const actions = {
  async epostaAyarlariGetir({ commit }, data) {
    return await axiosIns.post('/ayarlar/eposta-ayarlari-getir', data).then((res) => {
      commit('SET_EPOSTA', res.data.data);
      return res;
    });
  },
  async epostaAyarlariEkle({ commit }, data) {
    return await axiosIns.post('/ayarlar/eposta-ayarlari-ekle', data).then((res) => {
      commit('SET_EPOSTA', res.data.data);
      return res;
    });
  },
  async epostaAyarlariSil({ commit }, _id) {
    return await axiosIns.post('/ayarlar/eposta-ayarlar-sil', { _id }).then((res) => {
      commit('SET_SMS_DELETE', _id);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
