export default [
  {
    path: '/urun',
    name: 'urun',
    redirect: '/urun/listele',
    component: () => import(/* webpackChunkName: "urun" */ '@/views/urunler/index.vue'),
    children: [
      {
        path: '/urun/listele',
        name: 'urun-listele',
        component: () => import(/* webpackChunkName: "urunler" */ '@/views/urunler/listele.vue'),
      },
      {
        path: '/urun/sirala',
        name: 'urun-siralama',
        component: () => import(/* webpackChunkName: "urunSiralama" */ '@/views/urunler/urunSiralama.vue'),
      },
      {
        path: '/urun/ekle',
        name: 'urun-ekle',
        component: () => import(/* webpackChunkName: "urun-ekle" */ '@/views/urunler/urunTanimla.vue'),
      },
      {
        path: '/urun/guncelle/:k_no?',
        name: 'urun-guncelle',
        component: () => import(/* webpackChunkName: "urun-ekle" */ '@/views/urunler/urunTanimla.vue'),
      },
    ],
  },
];
