export default [
  //TANIMLAMALAR
  {
    path: '/tanimlama/kategoriler',
    name: 'kategoriler',
    component: () => import(/* webpackChunkName: "kategoriler" */ '@/views/tanimlamalar/kategoriler/index.vue'),
  },
  {
    path: '/tanimlama/urun-kategoriler',
    name: 'urun-kategoriler',
    component: () => import(/* webpackChunkName: "urun-kategoriler" */ '@/views/tanimlamalar/urunKategoriler/index.vue'),
  },
  {
    path: '/tanimlama/bolgeler',
    name: 'bolgeler',
    component: () => import(/* webpackChunkName: "bolgeler" */ '@/views/tanimlamalar/bolgeler/index.vue'),
  },
  {
    path: '/tanimlama/iptal-politikalari',
    name: 'iptal-politikalari',
    component: () => import(/* webpackChunkName: "iptal-politikalari" */ '@/views/tanimlamalar/iptalPolitikalari/index.vue'),
  },
  {
    path: '/tanimlama/taglar',
    name: 'taglar',
    component: () => import(/* webpackChunkName: "taglar" */ '@/views/tanimlamalar/taglar/index.vue'),
  },
  {
    path: '/tanimlama/ekstra-hizmetler',
    name: 'ekstra-hizmetler',
    component: () => import(/* webpackChunkName: "paket-listesi" */ '@/views/tanimlamalar/ekstraHizmetler/index.vue'),
  },
  {
    path: '/tanimlama/kur-islemleri',
    name: 'kur-islemleri',
    component: () => import(/* webpackChunkName: "hizmet-listesi" */ '@/views/tanimlamalar/kurlar/index.vue'),
  },
];
