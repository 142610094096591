import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  iletisim: [],
};
const getters = {
  getIletisim: (state) => state.iletisim,
};
const mutations = {
  SET_ILETISIM(state, payload) {
    state.iletisim = payload;
  },
  SET_ILETISIM_DELETE(state, id) {
    let index = state.iletisim.findIndex((x) => x._id == id);
    state.iletisim.splice(index, 1);
  },
};
const actions = {
  async iletisimGetir({ commit }, data) {
    return await axiosIns.post('/web-yonetim/iletisim-getir', data).then((res) => {
      commit('SET_ILETISIM', res.data.data);
      return res;
    });
  },
  async iletisimEkle({ commit }, data) {
    return await axiosIns.post('/web-yonetim/iletisim-ekle', data).then((res) => {
      commit('SET_ILETISIM', res.data.data);
      return res;
    });
  },
  async iletisimSil({ commit }, _id) {
    return await axiosIns.post('/web-yonetim/sil-sil', { _id }).then((res) => {
      commit('SET_ILETISIM_DELETE', _id);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
