import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  rezervasyonlar: [],
};
const getters = {
  getRezervasyonlar: (state) => state.rezervasyonlar,
};
const mutations = {
  SET_REZERVAYON(state, payload) {
    state.rezervasyonlar = payload;
  },
  SET_REZERVAYON_PUSH(state, payload) {
    state.rezervasyonlar.push(payload);
  },
  SET_REZERVAYON_UPDATE(state, payload) {
    let index = state.rezervasyonlar.findIndex((x) => x._id == payload._id);
    state.rezervasyonlar[index] = payload;
  },
  SET_REZERVAYON_DELETE(state, k_no) {
    let index = state.rezervasyonlar.findIndex((x) => x.k_no == k_no);
    state.rezervasyonlar.splice(index, 1);
  },
};
const actions = {
  async rezervasyonListele({ commit }, data) {
    return await axiosIns.post('/rezervasyon/rezervasyon-listele', data).then((res) => {
      commit('SET_REZERVAYON', res.data.data);
      return res;
    });
  },
  async rezervasyonEkle({ commit }, data) {
    return await axiosIns.post('/rezervasyon/rezervasyon-ekle', data).then((res) => {
      commit('SET_REZERVAYON_PUSH', res.data.data);
      return res;
    });
  },
  async rezervasyonGuncelle({ commit }, data) {
    return await axiosIns.post('/rezervasyon/rezervasyon-guncelle', data).then((res) => {
      commit('SET_REZERVAYON_UPDATE', res.data.data);
      return res;
    });
  },
  async rezervasyonSil({ commit }, k_no) {
    return await axiosIns.post('/rezervasyon/rezervasyon-sil', { k_no }).then((res) => {
      commit('SET_REZERVAYON_DELETE', k_no);
      return res;
    });
  },
  async rezervasyonStatuDurum({}, payload) {
    return await axiosIns.post('/rezervasyon/rezervasyon-statu-durum', payload).then((res) => {
      return res;
    });
  },
  async rezervasyonOdemeDurum({ commit }, payload) {
    return await axiosIns.post('/rezervasyon/rezervasyon-odeme-durum', payload).then((res) => {
      return res;
    });
  },
  async handlerRezervasyonFindOne({}, k_no) {
    return await axiosIns.post('/rezervasyon/rezervasyon-find-one', { k_no }).then((res) => {
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
