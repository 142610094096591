import axiosIns from '@/libs/axios';
const state = {
  urun_kategoriler: [],
};
const getters = {
  getUrunKategoriler: (state) => state.urun_kategoriler,
};
const mutations = {
  SET_URUN_KATEGORILER(state, payload) {
    state.urun_kategoriler = payload;
  },
  SET_URUN_KATEGORILER_PUSH(state, payload) {
    state.urun_kategoriler.push(payload);
  },
  SET_URUN_KATEGORILER_UPDATE(state, payload) {
    let index = state.urun_kategoriler.findIndex((x) => x._id == payload._id);
    state.urun_kategoriler[index] = payload;
  },
  SET_URUN_KATEGORILER_DELETE(state, k_no) {
    let altindex = state.urun_kategoriler.findIndex((x) => x.kategori_k_no == k_no);
    if (altindex != -1) {
      state.urun_kategoriler.splice(altindex, 1);
    }
    let index = state.urun_kategoriler.findIndex((x) => x.k_no == k_no);
    state.urun_kategoriler.splice(index, 1);
  },
};
const actions = {
  async urunKategoriListele({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/urun-kategori-listele', data).then((res) => {
      commit('SET_URUN_KATEGORILER', res.data.data);
      return res;
    });
  },
  async urunKategoriEkle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/urun-kategori-ekle', data).then((res) => {
      commit('SET_URUN_KATEGORILER_PUSH', res.data.data);
      return res;
    });
  },
  async urunKategoriGuncelle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/urun-kategori-guncelle', data).then((res) => {
      commit('SET_URUN_KATEGORILER_UPDATE', res.data.data);
      return res;
    });
  },
  async urunKategoriSil({ commit }, k_no) {
    return await axiosIns.post('/tanimlamalar/urun-kategori-sil', { k_no }).then((res) => {
      commit('SET_URUN_KATEGORILER_DELETE', k_no);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
