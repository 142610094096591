import axiosIns from '@/libs/axios';
const state = {
  odemeler: [],
};
const getters = {
  getOdemeler: (state) => state.odemeler,
};
const mutations = {
  SET_ODEMELER(state, payload) {
    state.odemeler = payload;
  },
  SET_ODEMELER_PUSH(state, payload) {
    state.odemeler.push(payload);
  },
  SET_ODEMELER_UPDATE(state, payload) {
    let index = state.odemeler.findIndex((x) => x._id == payload._id);
    state.odemeler[index] = payload;
  },
  SET_ODEMELER_DELETE(state, k_no) {
    let index = state.odemeler.findIndex((x) => x.k_no == k_no);
    state.odemeler.splice(index, 1);
  },
};
const actions = {
  async odemeListele({ commit }, data) {
    return await axiosIns.post('/finans/odeme-listele', data).then((res) => {
      commit('SET_ODEMELER', res.data.data);
      return res;
    });
  },
  async odemeEkle({ commit }, data) {
    return await axiosIns.post('/finans/odeme-ekle', data).then((res) => {
      commit('SET_ODEMELER_PUSH', res.data.data);
      return res;
    });
  },
  async odemeGuncelle({ commit }, data) {
    return await axiosIns.post('/finans/odeme-guncelle', data).then((res) => {
      commit('SET_ODEMELER_UPDATE', res.data.data);
      return res;
    });
  },
  async odemeSil({ commit }, k_no) {
    return await axiosIns.post('/finans/odeme-sil', { k_no }).then((res) => {
      commit('SET_ODEMELER_DELETE', k_no);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
