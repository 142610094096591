import axiosIns from '@/libs/axios';

const state = {
  sosyal_medya: [],
};
const getters = {
  getSosyalMedyaListele: (state) => state.sosyal_medya,
};
const mutations = {
  SET_SOSYAL_MEDYA(state, payload) {
    state.sosyal_medya = payload;
  },
  SET_SOSYAL_MEDYA_PUSH(state, payload) {
    state.sosyal_medya.push(payload);
  },
  SET_SOSYAL_MEDYA_UPDATE(state, payload) {
    let index = state.sosyal_medya.findIndex((x) => x._id == payload._id);
    state.sosyal_medya[index] = payload;
  },
  SET_SOSYAL_MEDYA_DELETE(state, id) {
    let index = state.sosyal_medya.findIndex((x) => x._id == id);
    state.sosyal_medya.splice(index, 1);
  },
};
const actions = {
  async sosyalMedyaListele({ commit }) {
    return await axiosIns.post('/ayarlar/sosyal-medya-listele').then((res) => {
      if (res.data.success == true) {
        commit('SET_SOSYAL_MEDYA', res.data.data);
      }
    });
  },
  async sosyalMedyaEkle({ commit }, payload) {
    return await axiosIns.post('/ayarlar/sosyal-medya-ekle', payload).then((res) => {
      commit('SET_SOSYAL_MEDYA_PUSH', res.data.data);
      return res;
    });
  },
  async sosyalMedyaGuncelle({ commit }, payload) {
    return await axiosIns.post('/ayarlar/sosyal-medya-guncelle', payload).then((res) => {
      commit('SET_SOSYAL_MEDYA_UPDATE', res.data.data);
      return res;
    });
  },
  async sosyalMedyaSil({ commit }, _id) {
    return await axiosIns.post('/ayarlar/sosyal-medya-sil', { _id }).then((res) => {
      commit('SET_SOSYAL_MEDYA_DELETE', _id);
      return res;
    });
  },
};

export default {
  namespace: true,
  state,
  getters,
  actions,
  mutations,
};
