import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  bolgeler: [],
};
const getters = {
  getBolgeler: (state) => state.bolgeler,
};
const mutations = {
  SET_BOLGELER(state, payload) {
    state.bolgeler = payload;
  },
  SET_BOLGELER_PUSH(state, payload) {
    state.bolgeler.push(payload);
  },
  SET_BOLGELER_UPDATE(state, payload) {
    let index = state.bolgeler.findIndex((x) => x._id == payload._id);
    state.bolgeler[index] = payload;
  },
  SET_BOLGELER_DELETE(state, k_no) {
    let altindex = state.bolgeler.findIndex((x) => x.bolge_k_no == k_no);
    if (altindex != -1) {
      state.bolgeler.splice(altindex, 1);
    }
    let index = state.bolgeler.findIndex((x) => x.k_no == k_no);
    state.bolgeler.splice(index, 1);
  },
};
const actions = {
  async bolgeListele({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/bolge-listele', data).then((res) => {
      commit('SET_BOLGELER', res.data.data);
      return res;
    });
  },
  async bolgeEkle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/bolge-ekle', data).then((res) => {
      commit('SET_BOLGELER_PUSH', res.data.data);
      return res;
    });
  },
  async bolgeGuncelle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/bolge-guncelle', data).then((res) => {
      commit('SET_BOLGELER_UPDATE', res.data.data);
      return res;
    });
  },
  async bolgeSil({ commit }, k_no) {
    return await axiosIns.post('/tanimlamalar/bolge-sil', { k_no }).then((res) => {
      commit('SET_BOLGELER_DELETE', k_no);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
