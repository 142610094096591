import useJwt from '@/auth/jwt/useJwt';
import router from '@/router';
const state = {
  user_data: {},
  user_role: {},
  user_firma: {},
  url: null,
};
const getters = {
  getUserData: (state) => state.user_data,
  getUserRole: (state) => state.user_role,
  getUserFirma: (state) => state.user_firma,
};
const mutations = {
  SET_USER_DATA(state, payload) {
    state.user_data = payload;
  },

  SET_DEFAULT_SUBE(state, payload) {
    state.user_data.default_sube = payload;
  },

  SET_USER_ROLE(state, payload) {
    state.user_role = payload;
  },

  SET_USER_FIRMA(state, payload) {
    state.user_firma = payload;
  },
  SET_URL(state, payload) {
    state.url = payload;
  },
};
const actions = {
  login({ commit, dispatch, getters }, data) {
    return useJwt.login(data).then(async (res) => {
      if (res.data.success == true) {
        useJwt.setToken(res.data.access_token);
        const userData = res.data.data.user;
        const userRole = res.data.data.role;
        // const userFirma = res.data.data.firma[0];
        localStorage.setItem('userData', JSON.stringify(userData));
        commit('SET_USER_DATA', userData);
        commit('SET_USER_ROLE', userRole);
        dispatch('dilAyarlariGetir');
        // commit('SET_USER_FIRMA', userFirma);
        return res;
      }
    });
  },

  logout() {
    localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
    localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

    localStorage.removeItem('userData');
    localStorage.removeItem('sideturkey');
    router.push({ name: 'login' });
  },

  initAuth({ dispatch, commit, state, getters }, route) {
    const token = useJwt.getToken();
    if (token) {
      useJwt
        .checkToken({ token })
        .then(async (res) => {
          if (res.data.success == true) {
          } else {
            dispatch('redirectAndRemove');
          }
        })
        .catch((err) => {
          if (err.status === 401 && err.data.success === false) {
            dispatch('redirectAndRemove');
          }
        });
    } else {
      dispatch('redirectAndRemove');
    }
  },

  redirectAndRemove() {
    localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
    localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

    localStorage.removeItem('userData');
    localStorage.removeItem('sideturkey');
    if (state.url !== 'reset-password' && state.url !== 'forgot-password') {
      router.push({ name: 'login' });
    }
  },

  // initAuth({ dispatch, commit }) {
  //   let Token = this._vm.$cookies.get("");
  //   if (
  //     Token == "undefined" ||
  //     Token == null ||
  //     Token == "null" ||
  //     Token == "0" ||
  //     Token == 0
  //   ) {
  //     commit("SET_ACCESS_TOKEN");
  //     commit("SET_LOGIN_PROFILE_REMOVE", {
  //       Logo: "",
  //       Avatar: "",
  //       Agency: {},
  //       AgencyUsers: {},
  //     });
  //     commit("SET_PERIODID_REMOVE");
  //     commit("SET_LOGIN", { Token });
  //   } else {
  //     dispatch("fetchProfile", Token);
  //   }
  // },

  // logout({ commit }) {
  //   commit("SET_ACCESS_TOKEN");
  //   commit("SET_LOGIN_PROFILE_REMOVE");
  //   commit("SET_PERIODID_REMOVE");
  //   commit("SET_LOGIN", {});
  // },
  // // get current login user info
  // fetchProfile({ dispatch, commit }, token) {
  //   return request({
  //     url: "/auth-login-user-profile-post",
  //     method: "post",
  //     data: {
  //       token,
  //     },
  //   }).then((res) => {
  //     if (res.status == true) {
  //       commit("SET_USER_DATA", res.AgencyUsers);
  //       commit("SET_USER_NAME", res.AgencyUsers.AdSoyad);
  //       commit("SET_USER_AVATAR", res.AgencyUsers.Avatar);
  //       commit("SET_AGENCY_ID", res.Agency.id);
  //       commit("SET_AGENCY_NAME", res.Agency.Baslik);
  //       commit("SET_AGENCY_LOGO", res.Agency.Logo);
  //       commit("SET_AGENCY_AUTH", res.AgencyAuht);
  //       commit("SET_AGENCY_DATA", res.Agency);
  //       dispatch("getAgencyStatusGet");
  //     } else {
  //       if (router.app.$route.name != "login") {
  //         router.push({ name: "login" });
  //       }
  //     }
  //   });
  // },
};

export default {
  namespace: true,
  state,
  getters,
  actions,
  mutations,
};
