import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  interface: [],
};
const getters = {
  getInterface: (state) => state.interface,
};
const mutations = {
  SET_INTERFACE(state, payload) {
    state.interface = payload;
  },
};
const actions = {
  async mikrotikInterface({ commit }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      sube_k_no: store.getters.getUserData.default_sube,
      ...data,
    };
    return await axiosIns.get('/dashboard/mikrotik-interface-list', { params: payload }).then((res) => {
      commit('SET_INTERFACE', res.data.data);
      return res;
    });
  },
  async mikrotikMonitor({ commit }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      sube_k_no: store.getters.getUserData.default_sube,
      ...data,
    };
    return await axiosIns.get('/dashboard/mikrotik-monitor', { params: payload }).then((res) => {
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
