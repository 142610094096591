import axiosIns from '@/libs/axios';
const state = {
  diger_ayarlar: [],
};
const getters = {
  getDigerAyarlar: (state) => state.diger_ayarlar,
};
const mutations = {
  SET_DIGER(state, payload) {
    state.diger_ayarlar = payload;
  },
  SET_DIGER_DELETE(state, id) {
    let index = state.diger_ayarlar.findIndex((x) => x._id == id);
    state.diger_ayarlar.splice(index, 1);
  },
};
const actions = {
  async digerAyarlariGetir({ commit }, data) {
    return await axiosIns.post('/ayarlar/diger-ayarlari-getir', data).then((res) => {
      commit('SET_DIGER', res.data.data);
      return res;
    });
  },
  async digerAyarlariEkle({ commit }, data) {
    return await axiosIns.post('/ayarlar/diger-ayarlari-ekle', data).then((res) => {
      commit('SET_DIGER', res.data.data);
      return res;
    });
  },
  async digerAyarlariSil({ commit }, _id) {
    return await axiosIns.post('/ayarlar/diger-ayarlar-sil', { _id }).then((res) => {
      commit('SET_DIGER_DELETE', _id);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
