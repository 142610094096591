export default [
  {
    path: '/raporlar',
    name: 'raporlar',
    component: () => import(/* webpackChunkName: "raporlar" */ '@/views/ayarlar/index.vue'),
  },
  {
    path: '/kullanicilar',
    name: 'kullanicilar',
    component: () => import(/* webpackChunkName: "kullanicilar" */ '@/views/kullanicilar/index.vue'),
  },
  {
    path: '/kullanicilar/yetkiler',
    name: 'yetkiler',
    component: () => import(/* webpackChunkName: "yetkiler" */ '@/views/kullanicilar/kullaniciYetkiler.vue'),
  },
  {
    path: '/ayarlar',
    name: 'ayarlar',
    redirect: '/ayarlar/menu',
    component: () => import(/* webpackChunkName: "ayarlar" */ '@/views/ayarlar/index.vue'),
    children: [
      {
        path: '/ayarlar/menu',
        name: 'ayar-menu',
        component: () => import(/* webpackChunkName: "ayar-menu" */ '@/views/ayarlar/menu/index.vue'),
      },
      {
        path: '/ayarlar/seo',
        name: 'ayar-seo',
        component: () => import(/* webpackChunkName: "ayar-seo" */ '@/views/ayarlar/seo/index.vue'),
      },
      {
        path: '/ayarlar/dil',
        name: 'ayar-dil',
        component: () => import(/* webpackChunkName: "ayar-dil" */ '@/views/ayarlar/dil/index.vue'),
      },
      {
        path: '/ayarlar/mail',
        name: 'ayar-mail',
        component: () => import(/* webpackChunkName: "ayar-mail" */ '@/views/ayarlar/mail/index.vue'),
      },
      {
        path: '/ayarlar/sosyal-medya',
        name: 'ayar-sosyal-medya',
        component: () => import(/* webpackChunkName: "ayar-sosyal-medya" */ '@/views/ayarlar/sosyalMedya/index.vue'),
      },
      {
        path: '/ayarlar/firma-bilgi',
        name: 'ayar-firma-bilgi',
        component: () => import(/* webpackChunkName: "ayar-firma-bilgi" */ '@/views/ayarlar/firmaBilgileri/index.vue'),
      },
      {
        path: '/ayarlar/odeme-ayarlari',
        name: 'ayar-odeme',
        component: () => import(/* webpackChunkName: "odeme-ayarlari" */ '@/views/ayarlar/odemeAyarlari/index.vue'),
      },
      {
        path: '/ayarlar/diger-ayarlari',
        name: 'ayar-diger',
        component: () => import(/* webpackChunkName: "odeme-ayarlari" */ '@/views/ayarlar/digerAyarlar/index.vue'),
      },
    ],
  },
];
