import axiosIns from '@/libs/axios';
const state = {
  firma_bilgi: [],
};
const getters = {
  getFirmaBilgileri: (state) => state.firma_bilgi,
};
const mutations = {
  SET_FIRMA_BILGI(state, payload) {
    state.firma_bilgi = payload;
  },
  SET_FIRMA_BILGI_DELETE(state, id) {
    let index = state.firma_bilgi.findIndex((x) => x._id == id);
    state.firma_bilgi.splice(index, 1);
  },
};
const actions = {
  async firmaBilgiGetir({ commit }, data) {
    return await axiosIns.post('/ayarlar/firma-bilgi-getir', data).then((res) => {
      commit('SET_FIRMA_BILGI', res.data.data);
      return res;
    });
  },
  async firmaBilgiEkle({ commit }, data) {
    return await axiosIns
      .post('/ayarlar/firma-bilgi-ekle', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        commit('SET_FIRMA_BILGI', res.data.data);
        return res;
      });
  },
  async firmaBilgiSil({ commit }, _id) {
    return await axiosIns.post('/ayarlar/firma-bilgi-sil', { _id }).then((res) => {
      commit('SET_SMS_DELETE', _id);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
